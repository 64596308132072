<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="请假学生">
              <a-input allow-clear v-model="form.studentName" placeholder="姓名或学号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="处理状态">
              <a-select allow-clear v-model="form.status" placeholder="请选择">
                <a-select-option v-for="item in handleStatusOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班型">
              <a-select allow-clear v-model="form.classType" placeholder="请选择">
                <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="请假日期">
              <a-range-picker
                style="width: 100%"
                @change="onChangeApplyDate"
                v-model="applyTimeArr"
                allowClear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="开课日期">
              <a-range-picker
                style="width: 100%"
                @change="onChangeLeaveDate"
                v-model="leaveDateArr"
                allowClear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="getListData(1)">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetFormLeave">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="stable-wrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 2000 }"
      >
        <span slot="studentName" slot-scope="text, record">
          <a @click="toStudentDetail(record)">{{ text }}（{{ record.studentRespDTO.code }}）</a>
        </span>
        <span slot="scheduleName" slot-scope="text, record">
          <router-link
            :to="{ path: '/workoutSchedule/scheduleDetail', query: { id: record.courseScheduleRespDTO.uuid } }"
          >
            {{ text }}</router-link
          >
        </span>
        <span slot="classType" slot-scope="text">{{ getDdlLabel(scheduleOptions.classType, text) }}</span>
        <span slot="acceptReschedule" slot-scope="text">{{ text ? '是' : '否' }}</span>
        <span slot="applicant" slot-scope="text, record">
          <span v-if="record.data.isStudent">
            {{ `${record.studentRespDTO.fullName}(${record.studentRespDTO.code})` }}
          </span>
          <span v-else>
            {{ extractOperator(text) }}
          </span>
        </span>
        <span slot="operator" slot-scope="text, record">
          <span v-if="record.data.isStudent">
            {{ `${record.studentRespDTO.fullName}(${record.studentRespDTO.code})` }}
          </span>
          <span v-else>
            {{ extractOperator(text) }}
          </span>
        </span>

        <span slot="action" slot-scope="text, record" style="word-break: keep-all">
          <template v-if="record.status === 'LEAVE_NOT_RESCHEDULED'">
            <a-button v-if="record.statusResp.canCancel" type="link" @click="handleRevokeLeave(record)">撤销请假</a-button>
            <a-button v-if="record.statusResp.canAdjust" type="link" @click="hadnleAdjustschedule(record)">调课</a-button>
          </template>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import { postReq, loadClassAdminOptions, revokeLeave } from '@/api/schedule';
import { getDdlLabel, extractOperator } from '@/utils/util';
import BaseList from '@/components/BaseList/index.vue';
import { getTreeList } from '@/api/headTeacher';

const leaveOptions = [
  { label: '学生身体不适', value: 'UNWELL' },
  { label: '学生活动冲突', value: 'ACTIVITY_CONFLICT' },
  { label: '网络或设备故障', value: 'NETWORK_DEVICE_FAILURE' },
  { label: '其他', value: 'OTHER' },
];

const handleStatusOptions = [
  { label: '已取消', value: 'CANCELLED' },
  { label: '已请假未调课', value: 'LEAVE_NOT_RESCHEDULED' },
  { label: '已请假已调课', value: 'LEAVE_RESCHEDULED' },
  { label: '已请假', value: 'LEAVE' },
  { label: '已请假未补课', value: 'LEAVE_NOT_MADE_UP' },
  { label: '已请假已补课', value: 'LEAVE_MADE_UP' },
];

const columns = [
  {
    title: '请假学生',
    dataIndex: 'studentName',
    key: 'studentName',
    fixed: 'left',
    width: 140,
    scopedSlots: { customRender: 'studentName' },
  },
  {
    title: '辅导老师',
    dataIndex: 'headmasterName',
    key: 'headmasterName',
  },
  {
    title: '申请人',
    key: 'data.applicant',
    dataIndex: 'data.applicant',
    scopedSlots: { customRender: 'applicant' },
  },
  {
    title: '申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
  },
  {
    title: '班型',
    key: 'classType',
    dataIndex: 'classType',
    scopedSlots: { customRender: 'classType' },
  },
  {
    title: '请假原因',
    key: 'reason',
    dataIndex: 'reason',
    customRender: (text) => leaveOptions.find((item) => item.value === text)?.label ?? text ?? '-',
  },
  {
    title: '请假课节',
    dataIndex: 'scheduleName',
    key: 'scheduleName',
    scopedSlots: { customRender: 'scheduleName' },
  },
  {
    title: '请假课节时间',
    key: 'leaveDate',
    dataIndex: 'leaveDate',
  },
  {
    title: '处理人',
    key: 'data.operator',
    dataIndex: 'data.operator',
    scopedSlots: { customRender: 'operator' },
  },
  {
    title: '处理状态',
    key: 'status',
    dataIndex: 'status',
    customRender: (text) => handleStatusOptions.find((item) => item.value === text)?.label ?? text ?? '-',
  },
  {
    title: '授课老师',
    key: 'teacherName',
    dataIndex: 'teacherName',
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  extends: BaseList,

  data() {
    return {
      userInfo: {},
      applyTimeArr: [],
      leaveDateArr: [],
      treeData: [],
      applyTimeStart: null,
      applyTimeEnd: null,
      leaveDateStart: null,
      leaveDateEnd: null,
      getDdlLabel,
      extractOperator,
      scheduleOptions: {
        classType: [],
        courseType: [],
        classStatus: [],
        leaveStatus: [],
        formClassCapacityStatus: [],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      columns,
      searchUrl: `/api/admin/scheduling/leave`,
      form: {
        studentName: undefined,
        ownerId: undefined,
        applyTimeStart: null,
        applyTimeEnd: null,
        leaveDateStart: null,
        leaveDateEnd: null,
        status: undefined,
      },
      classAdminList: [],
      leaveOptions,
      handleStatusOptions,
    };
  },
  created() {
    this.userInfo = storage.get('userInfo');
    this.getTreeList();
  },
  methods: {
    async handleRevokeLeave(record) {
      const { studentId, uuid: leaveId } = record;

      this.$confirmAnt({
        type: 'warning',
        title: '撤销请假',
        content: '撤销请假后，学员将继续跟随原班学习，记得提醒学员参加课程学习哦。',
        onOk: async () => {
          await revokeLeave({ studentId, leaveId });
          this.$message.success('撤销成功');
          this.getListData();
        },
      });
    },
    hadnleAdjustschedule(record) {
      const {
        studentRespDTO: { timezoneStd: studentTimezone },
        courseScheduleRespDTO: { uuid: courseScheduleId },
        studentId,
        uuid: leaveItemId,
      } = record;

      this.$router.push({
        path: '/workoutSchedule/adjustScheduleList',
        query: {
          studentTimezone,
          courseScheduleId,
          studentId,
          leaveItemId,
        },
      });
    },

    resetFormLeave() {
      this.form = {
        studentName: undefined,
        ownerId: undefined,
        deptId: undefined,
        applyTimeStart: null,
        applyTimeEnd: null,
        leaveDateStart: null,
        leaveDateEnd: null,
        status: undefined,
      };
      this.applyTimeArr = [];
      this.leaveDateArr = [];
      this.getListData(1);
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    onChangeApplyDate(date) {
      if (date.length > 0) {
        this.form.applyTimeStart = date[0].format('YYYY-MM-DD');
        this.form.applyTimeEnd = date[1].format('YYYY-MM-DD');
      } else {
        this.form.applyTimeStart = null;
        this.form.applyTimeEnd = null;
      }
    },
    onChangeLeaveDate(date) {
      if (date.length > 0) {
        this.form.leaveDateStart = date[0].format('YYYY-MM-DD');
        this.form.leaveDateEnd = date[1].format('YYYY-MM-DD');
      } else {
        this.form.leaveDateStart = null;
        this.form.leaveDateEnd = null;
      }
    },
    loadClassAdminOptions(name) {
      const params = {
        name,
      };
      loadClassAdminOptions(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },

    toStudentDetail(record) {
      this.$router.push({
        path: '/studentDetail',
        query: {
          uuid: record.studentId,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.ant-form-item {
  margin-bottom: 0px;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0px 20px 20px;
  display: flex;
  justify-content: space-between;
}

.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.stable-wrap {
  background-color: #fff;
  padding: 16px 20px;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-bottom: 14px;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
</style>
